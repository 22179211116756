.css-qi1wfe-MuiToolbar-root{
  min-height: 57px !important;
}


.css-1vgi167-MuiInputBase-root-MuiInputBase-input-MuiOutlinedInput-input{
  display: flex !important;
}

.css-10egq61 {
  gap: 0px !important;
}

.css-1xnaaoc-MuiGrid-root>.MuiGrid-item {
  padding-left: 0px !important;
}

.css-bmihqa-MuiToolbar-root {
  padding-right: 50px !important;
  padding-left: 57px !important;
}
.css-5c1adp-MuiContainer-root{
  padding:0px ;
}
.css-n8pler-MuiTypography-root{
  margin: auto;
}

.css-nyzriw-MuiTypography-root{
  letter-spacing: normal  ;
}

.css-n345c9-MuiTypography-root{
  line-height: auto !important;
}

@media (max-width:462px) {
  .css-1xnaaoc-MuiGrid-root {
    padding-left: 40px !important;
  }

  .css-bmihqa-MuiToolbar-root {
    padding-right: 20px !important;
    padding-left: 20px !important;
  }

}

/* .swiper-pagination-bullet {
    background-color: #7E60BF;
  }
  
  .swiper-pagination-bullet-active {
    opacity: 1;
    background-color: #7E60BF;
  }  */
/* .swiper-wrapper{
    display:'flex !important' ;
  } */
.swiper-pagination {
  position: relative !important;
  top: 12px !important
}

.swiper {
  overflow: visible !important;
}

.swiper-pagination-bullet {
  background: #1d1c1c;
  opacity: 0.7;
}

.swiper-pagination-bullet-active {
  background: #7E60BF;
  opacity: 1;
}

.css-xe8btp-MuiButtonBase-root-MuiRadio-root {
  padding: 2px !important;
}

/* .css-xtyv33ot::-webkit-scrollbar{
    display: none;

    } */


/* .mySwiper .swiper-pagination {
  display: none;
} */

/* contact us */

.css-8kdxea-MuiButtonBase-root-MuiButton-root:hover {
  box-shadow: none !important;
}

/* .css-8kdxea-MuiButtonBase-root-MuiButton-root:hover{
    display: none;
    } */

.label[data-shrink=false]+.MuiInputBase-formControl .css-1sh6wjw-MuiInputBase-input-MuiOutlinedInput-input {
  outline: none !important;
}

/* ::-webkit-scrollbar-button {
      display: none;
  }

  .testimonial-scroll::-webkit-scrollbar {
    display: none;
} */


.swiper-container::-webkit-scrollbar {
  display: none;
}

.swiper-container {
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
  scrollbar-width: none;
  /* Firefox */
}

.animated-term {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 24px; /* Adjust height based on TextField */
  overflow: hidden; /* Hide any overflowing text */
  color: rgba(0, 0, 0, 0.5); /* Slightly lighter color for the animated term */
}

.scroll-up {
  animation: scroll-up 0.5s ease-in-out forwards; /* Use forwards to keep final state */
}

@keyframes scroll-up {
  0% {
    transform: translateY(100%); /* Start below the container */
    opacity: 0; /* Start hidden */
  }
  100% {
    transform: translateY(0); /* End at normal position */
    opacity: 1; /* Fully visible */
  }
}


/* Style the radio button */
.custom-radio {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 15px;
  height: 15px;
  border: 2px solid #7E60BF;
  border-radius: 50%;
  cursor: pointer;
  outline: none;
  transition: background-color 0.3s, border-color 0.3s;
}

/* Style when the radio button is checked */
.custom-radio:checked {
  background-color: #7E60BF;
  border-color: #7E60BF;
}

/* Optional: Style the radio button when it's focused */
.custom-radio:focus {
  border-color: #7E60BF;
}

